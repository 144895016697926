import React, { ChangeEvent, FC, FocusEvent, KeyboardEvent } from 'react';
import { Form } from 'react-bootstrap';
import classNames from 'classnames';
import styles from '../scss/formInput.module.scss';

interface FormInputProps {
  errorText?: string;
  error?: boolean;
  onBlur?(event: FocusEvent<HTMLInputElement, Element>): void;
  onChange(event: ChangeEvent<HTMLInputElement>): void;
  onEnter?(): void;
  id: string;
  name?: string;
  label?: string;
  width: string;
  placeholder?: string;
  required?: boolean;
  type?: string;
  pattern?: string;
  ariaLabel?: string;
  dataTestId: string;
  isDisabled?: boolean;
  value: string | number | string[] | undefined;
  min?: number;
  max?: number;
}

const FormInput: FC<FormInputProps> = ({
  errorText,
  error,
  onBlur,
  onChange,
  onEnter,
  id,
  name,
  label,
  width,
  placeholder,
  required,
  type = 'text',
  pattern,
  ariaLabel,
  dataTestId,
  isDisabled,
  value,
  min,
  max,
}) => {
  const handleKeyPress = (target: KeyboardEvent) => {
    if (target.key === 'Enter' && onEnter) {
      onEnter();
    }
  };

  return (
    <div style={{ width: window.innerWidth > 1020 ? width : '100%' }} className={styles.form_input_spacing}>
      <div
        className={classNames(styles.label_styling, {
          'disabled-text': isDisabled,
        })}
      >
        {label}
      </div>
      <Form.Group>
        <Form.Control
          id={id}
          value={value}
          name={name}
          required={required}
          type={type}
          pattern={pattern}
          onChange={onChange}
          onKeyDownCapture={handleKeyPress}
          placeholder={placeholder}
          onBlur={onBlur}
          aria-label={ariaLabel}
          data-testid={dataTestId}
          disabled={isDisabled}
          style={error ? { border: '1px solid red', borderRadius: '0.375rem' } : undefined}
          min={min}
          max={max}
        />
      </Form.Group>
      {/* eslint-disable-next-line */}
      {error ? (
        <p className='text-danger text-start mt-1 mb-0' style={{ fontSize: '10px' }}>
          {errorText}
        </p>
      ) : null}
    </div>
  );
};

export default FormInput;
