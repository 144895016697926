import { useFormik } from 'formik';
import { useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import * as Yup from 'yup';
import Button from '../../components/Button/button';
import DataDisplayCell from '../../components/DataDisplayCell/dataDisplayCell';
import { PRODUCT_NOTIFICATIONS_CONSENT, TEST_SELECTION_LIST } from '../../constants/constants';
import personalGenome from '../../statics/images/personal_genome.png';
import { Context, initialState } from '../../store/store';
import { recordGoogleAnalyticsEvent, recordGoogleAnalyticsSend } from '../../utils/helpers';
import './pit_styling.scss';

function ReviewAndSubmit({ onBtnClick, onBackClick, onPlaceOrder }) {
  const [state, dispatch] = useContext(Context);
  const [isChecked, setIsChecked] = useState(false);
  const [disableSubmitBtn, setDisableSubmitBtn] = useState(false);
  const cadIncluded = state.testRequestInfo.product_information !== initialState.testRequestInfo.product_information;

  const formik = useFormik({
    initialValues: {
      subject: state.testRequestInfo.subject,
    },
    validationSchema: Yup.object({
      subject: Yup.object({
        contact_info: Yup.array().of(
          Yup.object().shape({
            address: Yup.object({
              city: Yup.string().required('Required'),
              postal_code: Yup.string()
                .required('Required')
                .matches(/^[0-9]+$/, 'Invalid Zip Code')
                .length(5, 'Invalid Zip Code'),
              region: Yup.string().required('Required'),
              street_address: Yup.string().required('Required'),
            }),
            email: Yup.string(),
          }),
        ),
        name: Yup.object({
          first: Yup.string().required('Required'),
          last: Yup.string().required('Required'),
        }),
      }),
    }),
    onSubmit: async values => {
      setDisableSubmitBtn(true);
      recordGoogleAnalyticsEvent('User Interaction', 'Button Click', 'Next Button: Review & Submit');
      await onBtnClick(values);
      onPlaceOrder();
    },
  });

  useEffect(() => {
    recordGoogleAnalyticsSend('pageview', '/order?step=review', 'PIT Flow: Review & Submit');
  }, []);

  const handleMarketingConsent = event => {
    setIsChecked(event.target.checked);
    if (event.target.checked === true) {
      dispatch({
        type: 'SET_REQUEST_INFO',
        payload: {
          ...state.testRequestInfo,
          consents_granted: [...state.testRequestInfo.consents_granted, PRODUCT_NOTIFICATIONS_CONSENT],
        },
      });
    } else {
      // if marketing consent is selected, then we need to remove it
      dispatch({
        type: 'SET_REQUEST_INFO',
        payload: {
          ...state.testRequestInfo,
          consents_granted: state.testRequestInfo.consents_granted.filter(
            value => value !== PRODUCT_NOTIFICATIONS_CONSENT,
          ),
        },
      });
    }
  };

  const displayPatientData = () => {
    return (
      <div className='review_patient_data_cell'>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <DataDisplayCell
            dataLabel='Patient Name'
            dataValue={
              state.testRequestInfo.subject.name.first +
              (state.testRequestInfo.subject.name.middle
                ? ' ' + state.testRequestInfo.subject.name.middle + ' '
                : ' ') +
              state.testRequestInfo.subject.name.last
            }
          />
          <DataDisplayCell dataLabel='Patient Email' dataValue={state.testRequestInfo.subject.contact_info[0].email} />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <DataDisplayCell
            dataLabel='Patient Address'
            multiline={true}
            multiLine1={state.testRequestInfo.subject.contact_info[0].address.street_address}
            multiLine2={
              state.testRequestInfo.subject.contact_info[0].address.city +
              ', ' +
              state.testRequestInfo.subject.contact_info[0].address.region
            }
            multiLine3={state.testRequestInfo.subject.contact_info[0].address.postal_code}
          />
        </div>
      </div>
    );
  };
  const displayCadData = () => {
    if (!cadIncluded) {
      return null;
    }
    return (
      <>
        <div className='order_cell_divider' style={{ width: 'inherit', margin: '18px 5px' }} />
        <h3 className='fw-semibold h6 align-self-start' style={{ marginBottom: '18px', paddingLeft: '18px' }}>
          Clinical Data
        </h3>
        <div className='review_patient_data_cell'>
          <div className='d-flex flex-column'>
            <DataDisplayCell
              dataLabel='Current Smoking Status'
              dataValue={state.testRequestInfo.product_information.smoking.smoking_status}
            />
            <DataDisplayCell
              dataLabel='Type 2 Diabetes Diagnosis'
              dataValue={state.testRequestInfo.product_information.diabetes.diagnosed_with_type_2_diabetes}
            />
            <DataDisplayCell
              dataLabel='Blood Pressure'
              dataValue={
                state.testRequestInfo.product_information.blood_pressure.systolic_blood_pressure +
                '/' +
                state.testRequestInfo.product_information.blood_pressure.diastolic_blood_pressure
              }
            />
          </div>
          <div className='d-flex flex-column'>
            <DataDisplayCell
              dataLabel='Total Cholesterol Level'
              dataValue={state.testRequestInfo.product_information.cholesterol.total_cholesterol}
            />
            <DataDisplayCell
              dataLabel='HDL Cholesterol Level'
              dataValue={state.testRequestInfo.product_information.cholesterol.hdl_cholesterol}
            />
            <DataDisplayCell
              dataLabel='Treated for Hypertension'
              dataValue={state.testRequestInfo.product_information.blood_pressure.antihypertension_medication_use}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <section className='questionnaire_wrapper align-items-center' style={{ maxWidth: '700px' }}>
      <h2 className='pit_flow_title' style={{ alignSelf: 'center', marginBottom: '18px' }}>
        Please review your order
      </h2>
      <div className='review_and_submit_cell'>
        <div className='d-flex justify-content-between align-items-center'>
          <div>
            <img src={personalGenome} alt='logo' className='white-checkout-genome-logo' />
          </div>
          <p className='fw-bold mb-0' style={{ color: '#5F6267', marginTop: '10px' }}>
            ${Number(state.stripePaymentInfo.total_cost).toFixed(2)}
          </p>
        </div>
        <ul className='fs-sm text-start mt-3 mb-0' style={{ lineHeight: '1.9' }}>
          {TEST_SELECTION_LIST.map((item, index) => {
            const isCadItem = item.includes('CAD');
            return (
              /* eslint-disable-next-line */
              cadIncluded ? <li key={'li_' + index}>{item}</li> : !isCadItem && <li key={'li_' + index}>{item}</li>
            );
          })}
        </ul>
        <div className='order_cell_divider' style={{ width: 'inherit' }} />
        <div className='patient_info_header'>
          <h3 className='fw-semibold h6'>Patient Information</h3>
        </div>
        {displayPatientData()}
        <div className='order_cell_divider' style={{ width: 'inherit', margin: '18px 5px' }} />
        <h3 className='fw-semibold h6 align-self-start' style={{ marginBottom: '18px', paddingLeft: '18px' }}>
          Family History
        </h3>
        {Object.keys(state.testRequestInfo.family_history).map(questionId => (
          <div
            key={questionId}
            className='d-flex, flex-column, justify-content-between, w-100'
            style={{
              padding: '0 18px',
              marginBottom: '18px',
            }}
          >
            <div style={{ fontSize: '12px', textAlign: 'left' }}>
              {state.testRequestInfo.family_history[questionId].question_text}
            </div>
            <div style={{ display: 'flex', marginTop: '12px' }}>
              <div style={{ fontSize: '12px', fontWeight: '700', textAlign: 'left' }}>Answer: </div>
              <div style={{ fontSize: '12px', textAlign: 'left', marginLeft: '5px' }}>
                {state.testRequestInfo.family_history[questionId].response}
              </div>
            </div>
          </div>
        ))}
        {displayCadData()}
      </div>
      <Form.Check
        className='fs-sm text-start'
        style={{ marginTop: '18px' }}
        type='checkbox'
        checked={isChecked}
        label=' Please notify me of new tests and genetic insights that become available using the
        whole-genome sequence data collected with my MyOme Proactive Health order.'
        onChange={handleMarketingConsent}
      />
      <div className='d-flex justify-content-center mt-4'>
        <Button
          type='button'
          buttonLabel='Previous'
          styleClass='secondaryButtonStyling'
          buttonStyling={{ marginRight: '18px' }}
          onClick={() => onBackClick('consents')}
        />
        <Button
          type='button'
          buttonLabel='Place Order'
          dataTestId='placeOrderBtn'
          styleClass='primaryButtonStyling'
          disabled={disableSubmitBtn}
          onClick={() => formik.handleSubmit()}
        />
      </div>
    </section>
  );
}

export default ReviewAndSubmit;
