import React, { FC, MouseEvent } from 'react';
import Button from 'react-bootstrap/Button';
import './button.scss';

interface ButtonProps {
  onClick(event: MouseEvent<HTMLButtonElement>): void;
  buttonLabel: string;
  styleClass?: string;
  buttonStyling?: React.CSSProperties;
  type?: 'button' | 'submit' | 'reset';
  ariaLabel?: string;
  disabled?: boolean;
  dataTestId?: string;
}

const DefaultButton: FC<ButtonProps> = ({
  onClick,
  buttonLabel,
  styleClass,
  buttonStyling,
  type,
  ariaLabel,
  disabled,
  dataTestId,
}) => {
  return (
    <Button
      disabled={disabled}
      type={type}
      aria-label={ariaLabel}
      className={styleClass}
      style={buttonStyling}
      onClick={onClick}
      data-testid={dataTestId}
    >
      {buttonLabel}
    </Button>
  );
};

export default DefaultButton;
