import React, { useContext, useEffect, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faSquare, faSquareCheck } from '@fortawesome/pro-regular-svg-icons';
import { Collapse, Form, Stack, ToggleButton } from 'react-bootstrap';

import { FormikProvider, getIn, useFormik } from 'formik';
import * as Yup from 'yup';

import Button from '../../components/Button/button';
import FormDropdown from '../../components/FormDropdown/formDropdown';
import FormInput from '../../components/FormInput/formInput';

import { SMOKING_STATUS } from '../../constants/constants';
import { Context, initialState } from '../../store/store';
import { firstLetterCapsOnly, recordGoogleAnalyticsEvent, recordGoogleAnalyticsSend } from '../../utils/helpers';
import './pit_styling.scss';

interface ClinicalDataProps {
  // eslint-disable-next-line
  onBtnClick: (data: any) => void;
  onBackClick: (step: string) => void;
}
export default function ClinicalData({ onBtnClick, onBackClick }: ClinicalDataProps) {
  const [state] = useContext(Context);
  const [skipCad, setSkipCad] = useState(false);

  // ALL FIELDS SHALL BE MADE REQUIRED UNLESS skipCad === true
  const formik = useFormik({
    initialValues: { product_information: state.testRequestInfo.product_information },
    validationSchema: Yup.object(
      skipCad
        ? {}
        : {
            product_information: Yup.object({
              smoking: Yup.object({
                smoking_status: Yup.string().required().label('Smoking Status'),
              }),
              diabetes: Yup.object({
                diagnosed_with_type_2_diabetes: Yup.string().required('Please select Yes or No'),
              }),
              cholesterol: Yup.object({
                total_cholesterol: Yup.number().required().max(320).min(130).label('Total Cholesterol'),
                hdl_cholesterol: Yup.number().required().max(100).min(20).label('HDL Cholesterol'),
              }),
              blood_pressure: Yup.object({
                diastolic_blood_pressure: Yup.number().required().max(130).min(60).label('Diastolic Blood Pressure'),
                systolic_blood_pressure: Yup.number().required().max(200).min(90).label('Systolic Blood Pressure'),
                antihypertension_medication_use: Yup.string().required('Please select Yes or No'),
              }),
            }),
          },
    ),
    // eslint-disable-next-line
    onSubmit: (values: any) => {
      recordGoogleAnalyticsEvent('User Interaction', 'Button Click', 'Next Button: Clinical Data');
      if (skipCad) {
        onBtnClick({ product_information: initialState.testRequestInfo.product_information });
      } else {
        onBtnClick(values);
      }
    },
  });
  useEffect(() => {
    recordGoogleAnalyticsSend('pageview', '/order?step=clinical_data', 'PIT Flow: Clinical Data');
  }, []);
  return (
    <section className='questionnaire_wrapper text-start'>
      <h2 className='pit_flow_title'>Clinical Data</h2>
      <p className='fs-sm align-self-start mb-0'>
        {/* eslint-disable-next-line */}
        These fields are needed to perform analysis for the <abbr title='Coronary Artery Disease'>CAD</abbr> product. If
        you choose to skip this section, <abbr>CAD</abbr> will be removed from your order.
      </p>
      <FormikProvider value={formik}>
        <Stack className='me-auto mb-2 mt-3'>
          <ToggleButton
            id='cadSkipBtn'
            type='checkbox'
            className='secondaryButtonStyling'
            checked={skipCad}
            value='skipCad'
            data-testid='cadSkipBtn'
            onChange={e => setSkipCad(e.currentTarget.checked)}
          >
            <FontAwesomeIcon
              icon={skipCad ? (faSquareCheck as IconDefinition) : (faSquare as IconDefinition)}
              className='me-2'
            />
            Skip and remove CAD from my order
          </ToggleButton>
        </Stack>
        <Collapse in={!skipCad}>
          <div className='border border-secondary-subtle rounded px-3 pt-lg-3 pb-4'>
            <Stack className='col-xs-12 col-md-6 col-lg-4'>
              <FormDropdown
                defaultValue={
                  formik.values.product_information.smoking.smoking_status
                    ? [
                        {
                          value: formik.values.product_information.smoking.smoking_status,
                          label: firstLetterCapsOnly(formik.values.product_information.smoking.smoking_status),
                        },
                      ]
                    : []
                }
                onClick={(selectedOption: string) => {
                  formik.handleChange('product_information.smoking.smoking_status')(selectedOption);
                }}
                width='100%'
                label='Current Smoking Status'
                options={SMOKING_STATUS}
                dataTestId='product_information.smoking.smoking_status'
                error={
                  getIn(formik.touched, 'product_information.smoking.smoking_status') &&
                  getIn(formik.errors, 'product_information.smoking.smoking_status')
                }
                errorText={getIn(formik.errors, 'product_information.smoking.smoking_status')}
                clinicianError=''
              />
            </Stack>

            <fieldset id='radioGroupDiabetes' className='d-flex align-items-center flex-wrap mt-4 mt-lg-3 mb-lg-3'>
              <legend className='fs-6 me-2 mb-2 mb-lg-0 flex-legend'>
                Is the patient diagnosed with Type 2 Diabetes?
              </legend>
              <Form.Check
                type='radio'
                label='Yes'
                name='product_information.diabetes.diagnosed_with_type_2_diabetes'
                id='radioDiabetesYes'
                value='yes'
                data-testid='product_information.diabetes.diagnosed_with_type_2_diabetes'
                checked={formik.values.product_information.diabetes.diagnosed_with_type_2_diabetes === 'yes'}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  formik.setFieldValue(
                    'product_information.diabetes.diagnosed_with_type_2_diabetes',
                    event.target.value,
                    true,
                  );
                }}
              />
              <Form.Check
                className='ms-2'
                type='radio'
                label='No'
                name='product_information.diabetes.diagnosed_with_type_2_diabetes'
                id='radioDiabetesNo'
                value='no'
                data-testid='product_information.diabetes.diagnosed_with_type_2_diabetes'
                checked={formik.values.product_information.diabetes.diagnosed_with_type_2_diabetes === 'no'}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  formik.setFieldValue(
                    'product_information.diabetes.diagnosed_with_type_2_diabetes',
                    event.target.value,
                    true,
                  );
                }}
              />
              {/* eslint-disable-next-line */}
              {formik.touched.product_information &&
                getIn(formik.errors, 'product_information.diabetes.diagnosed_with_type_2_diabetes') && (
                  <span className='required_error_styling' style={{ marginLeft: '8px' }}>
                    {getIn(formik.errors, 'product_information.diabetes.diagnosed_with_type_2_diabetes')}
                  </span>
                )}
            </fieldset>

            <fieldset id='product_information.cholesterol'>
              <legend className='sr-only'>Cholesterol Levels</legend>
              <Stack direction='horizontal' gap={4} className='align-items-start'>
                <Form.Group>
                  <FormInput
                    type='number'
                    value={formik.values.product_information.cholesterol.total_cholesterol}
                    onChange={formik.handleChange}
                    min={130}
                    max={320}
                    label='Total Cholesterol Level'
                    width='100%'
                    name='product_information.cholesterol.total_cholesterol'
                    dataTestId='product_information.cholesterol.total_cholesterol'
                    id='product_information.cholesterol.total_cholesterol'
                    onBlur={formik.handleBlur}
                    error={
                      getIn(formik.touched, 'product_information.cholesterol.total_cholesterol') &&
                      getIn(formik.errors, 'product_information.cholesterol.total_cholesterol')
                    }
                    errorText={getIn(formik.errors, 'product_information.cholesterol.total_cholesterol')}
                    aria-describedby='totalCholesterolDesc'
                  />
                  <Form.Text as='p' className='fs-xs mt-1' id='totalCholesterolDesc'>
                    mg/dL (must be between 130 - 320)
                  </Form.Text>
                </Form.Group>
                <Form.Group>
                  <FormInput
                    type='number'
                    value={formik.values.product_information.cholesterol.hdl_cholesterol}
                    onChange={formik.handleChange}
                    min={20}
                    max={100}
                    label='HDL Cholesterol Level'
                    width='100%'
                    name='product_information.cholesterol.hdl_cholesterol'
                    dataTestId='product_information.cholesterol.hdl_cholesterol'
                    id='product_information.cholesterol.hdl_cholesterol'
                    onBlur={formik.handleBlur}
                    error={
                      getIn(formik.touched, 'product_information.cholesterol.hdl_cholesterol') &&
                      getIn(formik.errors, 'product_information.cholesterol.hdl_cholesterol')
                    }
                    errorText={getIn(formik.errors, 'product_information.cholesterol.hdl_cholesterol')}
                    aria-describedby='hdlCholesterolDesc'
                  />
                  <Form.Text as='p' className='fs-xs mt-1' id='hdlCholesterolDesc'>
                    mg/dL (must be between 20 - 100)
                  </Form.Text>
                </Form.Group>
              </Stack>
            </fieldset>

            <fieldset id='product_information.blood_pressure'>
              <legend className='sr-only'>Blood Pressure</legend>
              <Stack direction='horizontal' gap={4} className='align-items-start'>
                <Form.Group>
                  <FormInput
                    type='number'
                    value={formik.values.product_information.blood_pressure.diastolic_blood_pressure}
                    onChange={formik.handleChange}
                    min={60}
                    max={130}
                    label='Diastolic Blood Pressure'
                    width='100%'
                    name='product_information.blood_pressure.diastolic_blood_pressure'
                    dataTestId='product_information.blood_pressure.diastolic_blood_pressure'
                    id='product_information.blood_pressure.diastolic_blood_pressure'
                    onBlur={formik.handleBlur}
                    error={
                      getIn(formik.touched, 'product_information.blood_pressure.diastolic_blood_pressure') &&
                      getIn(formik.errors, 'product_information.blood_pressure.diastolic_blood_pressure')
                    }
                    errorText={getIn(formik.errors, 'product_information.blood_pressure.diastolic_blood_pressure')}
                    aria-describedby='diastolicDesc'
                  />
                  <Form.Text as='p' className='fs-xs mt-1' id='diastolicDesc'>
                    mm HG (must be between 60 - 130)
                  </Form.Text>
                </Form.Group>
                <Form.Group>
                  <FormInput
                    type='number'
                    value={formik.values.product_information.blood_pressure.systolic_blood_pressure}
                    onChange={formik.handleChange}
                    min={90}
                    max={200}
                    label='Systolic Blood Pressure'
                    width='100%'
                    name='product_information.blood_pressure.systolic_blood_pressure'
                    dataTestId='product_information.blood_pressure.systolic_blood_pressure'
                    id='product_information.blood_pressure.systolic_blood_pressure'
                    onBlur={formik.handleBlur}
                    error={
                      getIn(formik.touched, 'product_information.blood_pressure.systolic_blood_pressure') &&
                      getIn(formik.errors, 'product_information.blood_pressure.systolic_blood_pressure')
                    }
                    errorText={getIn(formik.errors, 'product_information.blood_pressure.systolic_blood_pressure')}
                    aria-describedby='systolicDesc'
                  />
                  <Form.Text as='p' className='fs-xs mt-1' id='systolicDesc'>
                    mm HG (must be between 90 - 200)
                  </Form.Text>
                </Form.Group>
              </Stack>
              <fieldset
                id='radioGroupHypertension'
                className='d-flex align-items-center align-self-start flex-wrap flex-fill mt-2 mt-lg-1'
              >
                <legend className='fs-6 me-2 mb-2 mb-lg-0 flex-legend'>
                  Is the patient being treated for Hypertension?
                </legend>
                <Form.Check
                  type='radio'
                  label='Yes'
                  name='product_information.blood_pressure.antihypertension_medication_use'
                  id='radioHypertensionYes'
                  value='yes'
                  data-testid='product_information.blood_pressure.antihypertension_medication_use'
                  checked={formik.values.product_information.blood_pressure.antihypertension_medication_use === 'yes'}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    formik.setFieldValue(
                      'product_information.blood_pressure.antihypertension_medication_use',
                      event.target.value,
                      true,
                    );
                  }}
                />
                <Form.Check
                  className='ms-2'
                  type='radio'
                  label='No'
                  name='product_information.blood_pressure.antihypertension_medication_use'
                  id='radioHypertensionNo'
                  value='no'
                  data-testid='product_information.blood_pressure.antihypertension_medication_use'
                  checked={formik.values.product_information.blood_pressure.antihypertension_medication_use === 'no'}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    formik.setFieldValue(
                      'product_information.blood_pressure.antihypertension_medication_use',
                      event.target.value,
                      true,
                    );
                  }}
                />
                {/* eslint-disable-next-line */}
                {formik.touched.product_information &&
                  getIn(formik.errors, 'product_information.blood_pressure.antihypertension_medication_use') && (
                    <span className='required_error_styling' style={{ marginLeft: '8px' }}>
                      {getIn(formik.errors, 'product_information.blood_pressure.antihypertension_medication_use')}
                    </span>
                  )}
              </fieldset>
            </fieldset>
          </div>
        </Collapse>
      </FormikProvider>
      <div style={{ marginTop: '18px', display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          buttonLabel='Previous'
          styleClass='secondaryButtonStyling'
          buttonStyling={{ marginRight: '18px' }}
          onClick={() => onBackClick('questionnaire')}
        />
        <Button
          type='button'
          buttonLabel='Next'
          dataTestId='clinicalDataNextBtn'
          styleClass='primaryButtonStyling'
          onClick={() => formik.handleSubmit()}
        />
      </div>
    </section>
  );
}
