import { FC, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import DefaultButton from '../Button/button';
import FormInput from '../FormInput/formInput';
import styles from './promoCode.module.scss';

interface PromoCodeProps {
  initialValue: string;
  onSubmit(promoCode: string): Promise<string>;
  disabled: boolean;
}

const PromoCode: FC<PromoCodeProps> = ({ initialValue, onSubmit, disabled }) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [isClearOnly, setIsClearOnly] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [value, setValue] = useState<string>('');
  const [error, setError] = useState<string>('');

  useEffect(() => {
    if (initialValue) {
      setIsActive(true);
      setValue(initialValue);
      setIsClearOnly(true);
    }
  }, [initialValue]);

  useEffect(() => {
    setIsDisabled(disabled);
  }, [disabled]);

  const handleChange = (inputValue: string) => {
    setValue(inputValue);
    if (inputValue.length === 0) {
      setError('');
    }
  };

  const applyPromoCode = async () => {
    const error = await onSubmit(value);
    setError(error);
    if (!error) {
      setIsClearOnly(true);
    }
  };

  const clearPromoCode = async () => {
    setValue('');
    setIsClearOnly(false);
    const response = await onSubmit('');
    setError(response);
  };
  return (
    <>
      {!isDisabled ? (
        !isActive ? (
          <Button
            variant='link'
            data-testid='addPromotionCodeBtn'
            className={styles.addPromoCodeText}
            onClick={() => setIsActive(true)}
          >
            Add promotion code
          </Button>
        ) : (
          <div>
            <div className={styles.promoCodeInputWrapper}>
              <FormInput
                value={value}
                onChange={e => handleChange(e.target.value)}
                onEnter={() => {
                  if (!isClearOnly) {
                    applyPromoCode();
                  }
                }}
                width='70%'
                dataTestId='promo_code'
                id='promo_code'
                label='Promo Code'
                placeholder='Add promotion code'
                isDisabled={isClearOnly}
              ></FormInput>
              {isClearOnly ? (
                <DefaultButton
                  onClick={clearPromoCode}
                  styleClass='secondaryButtonStyling'
                  buttonLabel='Clear'
                  dataTestId='clearPromoCodeBtn'
                />
              ) : (
                <DefaultButton
                  onClick={applyPromoCode}
                  styleClass='secondaryButtonStyling'
                  buttonLabel='Apply'
                  disabled={value.length < 2}
                  dataTestId='applyPromoCodeBtn'
                />
              )}
            </div>
            {error ? <p className='text-danger fs-xs mt-1 mb-0 text-start'>{error}</p> : null}
          </div>
        )
      ) : value ? (
        <FormInput
          value={value}
          onChange={e => handleChange(e.target.value)}
          width='70%'
          dataTestId='promo_code'
          id='promo_code'
          label='Promo Code'
          placeholder='Add promotion code'
          isDisabled={isDisabled}
        ></FormInput>
      ) : null}
    </>
  );
};

export default PromoCode;
