import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faSquareInfo } from '@fortawesome/pro-solid-svg-icons';
import { Button, Modal } from 'react-bootstrap';

export default function EligibilityModal() {
  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow(!show);
  };
  return (
    <>
      <Button
        variant='link'
        size='sm'
        className='d-flex align-items-center fw-semibold text-decoration-none mb-1'
        style={{ marginTop: '-18px', marginLeft: '10px', color: '#003595' }}
        onClick={handleShow}
      >
        <FontAwesomeIcon icon={faSquareInfo as IconDefinition} className='me-1' />
        Eligibility
      </Button>
      <Modal show={show} onHide={handleShow} centered>
        <Modal.Header closeButton>
          <Modal.Title>Patient Eligibility</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='mb-1'>A patient must be:</p>
          <ul className='mb-3'>
            <li>18 years or older to place an order.</li>
            <li>40 years or older to order a CAD PRS test.</li>
          </ul>
          <p className='mb-0'>Tests may not be available in some states.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='secondary' onClick={handleShow}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
