import FlowLayout from '../../layouts/FlowLayout/flowLayout';
import FamilyQuestionnaire from './familyQuestionnaire';
import ClinicalData from './clinicalData';
import ReviewAndSubmit from './reviewAndSubmit';
import Consents, { IPRS_CAD } from './consents';
import OrderCheckout from './orderCheckout';
import WhatsNext from './whatsNext';

import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { placeTestRequest } from '../../store/actions';
import { Context } from '../../store/store';
import { isYoungerThan, recordGoogleAnalyticsEvent } from '../../utils/helpers';
import { MIN_AGE_CAD, PLACE_ORDER_STEPS, PLACE_ORDER_STEPS_WITH_CAD } from '../../constants/constants';

function PitFlowOrchestrator() {
  const [state, dispatch] = useContext(Context);
  const [searchParams, setSearchParams] = useSearchParams({ step: 'checkout' });
  const [makeRequest, setMakeRequest] = useState(false);
  const cadIneligible = isYoungerThan(state.testRequestInfo.subject.demographics.dob, MIN_AGE_CAD);
  const steps = cadIneligible ? PLACE_ORDER_STEPS : PLACE_ORDER_STEPS_WITH_CAD;

  useEffect(() => {
    if (!window.location.href.includes('step')) {
      window.location.search = '?step=checkout';
    }

    if (makeRequest) {
      // remove confirm email field and make request
      delete state.testRequestInfo.subject.contact_info[0].confirmEmail;
      delete state.testRequestInfo.subject.contact_info[0].address.street_address2;
      const payload = { ...state.testRequestInfo };
      if (!payload.order.product_ids.includes(IPRS_CAD)) {
        // This is a none CAD order, so we need to remove product_information
        delete payload.product_information;
      } else {
        // the API wants these two fields to be booleans instead of the 'yes' values selected by the user
        payload.product_information.diabetes.diagnosed_with_type_2_diabetes =
          state.testRequestInfo.product_information.diabetes.diagnosed_with_type_2_diabetes === 'yes';
        payload.product_information.blood_pressure.antihypertension_medication_use =
          state.testRequestInfo.product_information.blood_pressure.antihypertension_medication_use === 'yes';
      }
      placeTestRequest(payload)
        .then(() => {
          setSearchParams({ step: 'whats_next' });
        })
        .catch(() => {
          console.log('theres been an error!');
        });
      setMakeRequest(false);
    }
  }, [makeRequest, setSearchParams, state.testRequestInfo]);

  const combinePayload = (data, step) => {
    if (data.family_history) {
      dispatch({
        type: 'SET_REQUEST_INFO',
        payload: {
          ...state.testRequestInfo,
          family_history: data.family_history,
        },
      });
    } else if (data.product_information) {
      dispatch({
        type: 'SET_REQUEST_INFO',
        payload: {
          ...state.testRequestInfo,
          product_information: data.product_information,
        },
      });
    } else if (data.consents_granted) {
      dispatch({
        type: 'SET_REQUEST_INFO',
        payload: {
          ...state.testRequestInfo,
          consents_granted: [...data.consents_granted],
          order: data.order,
        },
      });
    } else {
      dispatch({
        type: 'SET_REQUEST_INFO',
        payload: { ...state.testRequestInfo, ...data },
      });
    }
    if (step) {
      setSearchParams({ step });
    }
  };

  const stepAfterQuestionaire = () => {
    return cadIneligible ? 'consents' : 'clinical_data';
  };

  const onBackBtnClick = step => {
    recordGoogleAnalyticsEvent('User Interaction', 'Button Click', `Back Button: ${step}`);
    setSearchParams({ step });
  };

  return (
    <FlowLayout step={searchParams.get('step')} steps={steps}>
      {searchParams.get('step') === 'checkout' ? (
        <OrderCheckout
          onBtnClick={data => {
            combinePayload(data, 'questionnaire');
          }}
        />
      ) : searchParams.get('step') === 'questionnaire' ? (
        <FamilyQuestionnaire
          onBackClick={step => onBackBtnClick(step)}
          onBtnClick={data => {
            combinePayload(data, stepAfterQuestionaire());
          }}
        />
      ) : searchParams.get('step') === 'clinical_data' ? (
        <ClinicalData
          onBackClick={step => onBackBtnClick(step)}
          onBtnClick={data => {
            combinePayload(data, 'consents');
          }}
        />
      ) : searchParams.get('step') === 'consents' ? (
        <Consents
          onBackClick={step => onBackBtnClick(step)}
          onBtnClick={data => {
            combinePayload(data, 'review');
          }}
        />
      ) : searchParams.get('step') === 'review' ? (
        <ReviewAndSubmit
          onBackClick={step => onBackBtnClick(step)}
          onBtnClick={data => {
            combinePayload(data);
          }}
          onPlaceOrder={() => setMakeRequest(true)}
        />
      ) : searchParams.get('step') === 'whats_next' ? (
        <WhatsNext
          onBtnClick={step => {
            setSearchParams({ step });
          }}
        />
      ) : null}
    </FlowLayout>
  );
}

export default PitFlowOrchestrator;
