import './flowLayout.scss';

import { ReactComponent as FullLogoSvg } from '@statics/images/full_logo.svg';
import Stepper from '../../components/Stepper/stepper';
import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';

function FlowLayout({ children, step, steps }) {
  const flowLayoutRef = useRef(null);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    flowLayoutRef.current.scrollTo(0, 0);
  }, [searchParams]);

  return (
    <div className='flowLayoutWrapper'>
      <div className='flow_header'>
        <FullLogoSvg alt='logo' className='logo-styling flow_logo_wrapper' />
        {step !== 'whats_next' ? (
          <div className='stepper_placement_wrapper'>
            <div className='flow_stepper_wrapper'>
              <Stepper
                currentStep={step}
                primaryColor='#003595'
                secondaryColor='#36CBD9'
                disabledColor='#ACAEB2'
                lineStylingMargin={window.innerWidth < 560 ? '0' : '0 8px'}
                totalSteps={steps}
                containerSize={window.innerWidth}
                showMobileStepper={false}
                duoTone={false}
              />
            </div>
          </div>
        ) : null}
      </div>
      <div
        className={classNames(
          'flow_layout_body',
          searchParams.get('step') === 'whats_next' ? 'flow_whats_next_layout' : '',
        )}
        ref={flowLayoutRef}
      >
        {children}
      </div>
    </div>
  );
}

export default FlowLayout;
