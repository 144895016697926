import { Field, FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import './pit_styling.scss';

import { ReactComponent as FullLogoSvg } from '@statics/images/full_logo.svg';
import { marked } from 'marked';
import { useContext, useEffect, useState } from 'react';
import { renderToString } from 'react-dom/server';
import Button from '../../components/Button/button';
import { fetchAllConsents } from '../../store/actions';
import { Context, initialState } from '../../store/store';
import { recordGoogleAnalyticsEvent, recordGoogleAnalyticsSend } from '../../utils/helpers';

export const IPRS_CAD = 'PR42014';

const PRODUCT_IDS = [
  'PR22020', // Single-Gene 84
  'PR32005', // Medication Response
  IPRS_CAD,
];

const GENERAL_CONSENT = 'myome:consent:GENERAL';
const DNAVISIT_CONSENT = 'myome:consent:DNAVISIT';

function Consents({ onBtnClick, onBackClick }) {
  const [state] = useContext(Context);
  const [allConsents, setAllConsents] = useState([]);
  const [dnaVisitConsent, setDnaVisitConsent] = useState();
  const [loading, setLoading] = useState(false);
  const cadIncluded = state.testRequestInfo.product_information !== initialState.testRequestInfo.product_information;
  const consentProducts = cadIncluded ? PRODUCT_IDS : PRODUCT_IDS.filter(id => id !== IPRS_CAD);

  marked.use({
    mangle: false,
    headerIds: false,
  });

  const formik = useFormik({
    initialValues: {
      uri1: [],
      uri2: [],
      uri3: [],
    },
    validationSchema: Yup.object({
      uri1: Yup.array().required('Required').min(1, 'Required'),
      uri2: Yup.array().required('Required').min(1, 'Required'),
      uri3: Yup.array().required('Required').min(1, 'Required'),
    }),
    onSubmit: values => {
      recordGoogleAnalyticsEvent('User Interaction', 'Button Click', 'Next Button: Consents');
      values.consents_granted = [dnaVisitConsent.consent_uri, ...allConsents.map(consent => consent.consent_uri)];
      values.order = {
        product_ids: consentProducts,
      };
      onBtnClick(values);
    },
  });

  useEffect(() => {
    // repopulate the values so checkboxes are checked user goes back
    formik.values.uri1 = state.testRequestInfo.consents_granted.filter(f => f.startsWith(GENERAL_CONSENT)).length
      ? ['general']
      : [];
    formik.values.uri2 = state.testRequestInfo.consents_granted.filter(f => f.startsWith(DNAVISIT_CONSENT)).length
      ? ['dnavisit']
      : [];
    formik.values.uri3 = state.testRequestInfo.consents_granted.filter(f => f.startsWith(GENERAL_CONSENT)).length
      ? ['familyHistory']
      : [];

    function fetchRelevantConsents() {
      setLoading(true);
      const consents = ['DNAVISIT', 'GENERAL', ...consentProducts];
      fetchAllConsents(consents).then(res => {
        console.log(`MY CONSENTS ${consents}`, res);
        if (res) {
          if (res) {
            // putting DNA Visit consent in a different variable to display separately
            const dnaIndex = res.findIndex(i => i.consent_uri.startsWith('myome:consent:DNAVISIT'));
            setDnaVisitConsent(res.splice(dnaIndex, 1)[0]);
            setAllConsents(res);
            setLoading(false);
          }
        }
      });
    }

    // then call our function
    if (!allConsents.length) {
      recordGoogleAnalyticsSend('pageview', '/order?step=consents', 'PIT Flow: Consents');
      fetchRelevantConsents();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allConsents.length]);

  const onConsentNext = () => {
    // check consent length without marketing consent:
    let consentsWithoutMarketing = [];
    if (state.testRequestInfo.consents_granted) {
      consentsWithoutMarketing = state.testRequestInfo.consents_granted.filter(m =>
        m.startsWith('myome:consent:PRODUCT_NOTIFICATIONS'),
      );
    }
    if (consentsWithoutMarketing.length <= 4) {
      formik.handleSubmit();
    }
  };

  const renderDnaVisitConsent = () => {
    const dnaMarkdown = dnaVisitConsent.consent_text;
    // Open a new window or tab with the rendered Markdown content
    const newWindow = window.open('', '_blank');
    if (newWindow) {
      newWindow.document.body.innerHTML = `
      <div>
          ${renderToString(<FullLogoSvg alt='myome' style={{ width: '130px', height: '40px' }} />)}
          <div style="padding: 18px 32px; font-size: 14px; font-family: Roboto;
              border: 1px solid #ACAEB2; border-radius: 8px; margin: 0 auto;
              width: 900px; font-size: 14px">
              ${marked.parse(dnaMarkdown)}
          </div>
      <div>`;
    }
  };

  const displayMarkdown = (item, index) => {
    const markdown = item.consent_text;
    return <div className='marked_styling' key={index} dangerouslySetInnerHTML={{ __html: marked.parse(markdown) }} />;
  };

  return (
    <div>
      {loading ? null : (
        <div className='questionnaire_wrapper'>
          <div className='pit_flow_title' style={{ alignSelf: 'flex-start' }}>
            Consents & Agreements
          </div>
          <div style={{ fontSize: '12px', alignSelf: 'flex-start', color: '#7C7E83', marginBottom: '18px' }}>
            Read and agree to the consent agreements below
          </div>
          <div className='shaded_consent_wrapper'>
            <div
              style={{
                fontWeight: 700,
                borderBottom: '1px solid #DADEE4',
                textAlign: 'left',
                padding: '18px 0 12px 18px',
              }}
            >
              Informed Consent Agreement
            </div>
            {loading ? (
              <div style={{ textAlign: 'center' }}>Loading...</div>
            ) : (
              <div style={{ padding: '18px', textAlign: 'justify' }}>
                {allConsents.map((item, index) => {
                  return displayMarkdown(item, index);
                })}
              </div>
            )}
          </div>
          <div style={{ fontWeight: '700', marginTop: '18px', textAlign: 'left' }}>
            By checking the boxes below you indicate that:
          </div>
          <div style={{ marginTop: '12px', textAlign: 'left', fontSize: '12px' }}>
            <ul>
              <li>
                You are of legal age to request the test services and consent hereto, or you are the parent, legal
                guardian, or person having legal authority to act on behalf of the individual who will receive the test
                services.
              </li>
              <li>
                You have read, or had read to you, and understand the above information. The decision to consent to, or
                to refuse, the above testing is voluntary and entirely yours.
              </li>
              <li>
                You have had the opportunity to discuss the testing, including the purposes, limitations, and possible
                risks with your healthcare provider or genetic counselor of your choice.
              </li>
              <li>
                You have all the information you want, and all your questions have been answered to your satisfaction.
              </li>
            </ul>
          </div>
          <div
            style={{ fontSize: '14px', fontWeight: '600', textAlign: 'left', color: '#5F6267', marginBottom: '12px' }}
          >
            Please scroll through and read the entire agreement before checking these boxes
          </div>
          <div>
            <FormikProvider value={formik}>
              <div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <label style={{ fontSize: '14px', textAlign: 'left', marginTop: '8px' }}>
                    <Field
                      type='checkbox'
                      name='uri1'
                      value='general'
                      style={{ marginRight: '5px' }}
                      data-testid='consentGeneralCheckbox'
                    />
                    <span>I accept MyOme&apos;s Informed Consent Agreement</span>
                    {formik.touched.uri1 && formik.errors.uri1 ? (
                      <div style={{ fontSize: '10px', color: 'red', margin: '1px 0 0 5px' }}>Required</div>
                    ) : null}
                  </label>
                  <label style={{ fontSize: '14px', textAlign: 'left', marginTop: '18px' }}>
                    <Field
                      type='checkbox'
                      name='uri2'
                      value='dnavisit'
                      style={{ marginRight: '5px' }}
                      data-testid='consentDNAVisitCheckbox'
                    />
                    <span>
                      I accept the{' '}
                      <span onClick={renderDnaVisitConsent} style={{ color: '#003595', cursor: 'pointer' }}>
                        DNAvisit Physician-of-Record
                      </span>{' '}
                      Informed Consent
                    </span>
                    {formik.touched.uri2 && formik.errors.uri2 ? (
                      <div style={{ fontSize: '10px', color: 'red', margin: '1px 0 0 5px' }}>Required</div>
                    ) : null}
                  </label>
                  <label style={{ fontSize: '14px', textAlign: 'left', marginTop: '18px' }}>
                    <Field
                      type='checkbox'
                      name='uri3'
                      value='familyHistory'
                      style={{ marginRight: '5px' }}
                      data-testid='consentfamilyHistory'
                    />
                    <span>
                      By checking this box, you acknowledge that the screening tests that you have ordered are not
                      intended for individuals who seek to understand the genetic basis of a known personal or family
                      history of inherited conditions, confirm prior genetic testing results, or identify genetic
                      diseases prenatal children during pregnancy.
                    </span>
                    {formik.touched.uri3 && formik.errors.uri3 ? (
                      <div style={{ fontSize: '10px', color: 'red', margin: '1px 0 0 5px' }}>Required</div>
                    ) : null}
                  </label>
                </div>
              </div>
            </FormikProvider>
            <div style={{ marginTop: '12px', textAlign: 'left', fontSize: '14px' }}>
              These consents will be emailed to you once your order is placed.
            </div>
          </div>
          <div style={{ marginTop: '18px', display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              buttonLabel='Previous'
              styleClass='secondaryButtonStyling'
              buttonStyling={{ marginRight: '18px' }}
              onClick={() => onBackClick(cadIncluded ? 'clinical_data' : 'questionnaire')}
            />
            <Button
              type='submit'
              dataTestId='consentSubmitBtn'
              buttonLabel='Next'
              styleClass='primaryButtonStyling'
              onClick={onConsentNext}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Consents;
