export const KEYCLOAK_URL = 'https://auth.myome.com';
export const KEYCLOAK_CLIENT_ID = 'patient-portal';
export const SENTRY_DSN =
  'https://7fe2161745e24f04a059623ab4e2ebc6@o4506186993238016.ingest.sentry.io/4506832886628352';

export const environments = {
  tst: {
    baseurl: 'https://patients.tst.myome.info',
    apiurl: 'https://api.tst.myome.info/0',
    ga_id: 'G-3D9FQ6NM9K',
    stripe_api_key:
      'pk_test_51LuhuOJBauKDT6tuaPTfQBHEJxbI1yvxs6YiqoZvETf42hyFws6wrMajRRHU7B7zTZRcKttFkNqZqNk2Xjq5HvLF00sqQ06Wsp',
    googleMapsApiKey: 'AIzaSyD0B_jmB39u-gRWewG4L3Fu_abu2kOY_1c',
    keycloak_realm: 'tst',
    sentry_enabled: true,
  },
  sbx: {
    baseurl: 'https://patients.sbx.myome.com',
    apiurl: 'https://api.sbx.myome.com/0',
    ga_id: 'G-3D9FQ6NM9K',
    stripe_api_key:
      'pk_test_51LuhuOJBauKDT6tuaPTfQBHEJxbI1yvxs6YiqoZvETf42hyFws6wrMajRRHU7B7zTZRcKttFkNqZqNk2Xjq5HvLF00sqQ06Wsp',
    googleMapsApiKey: 'AIzaSyD0B_jmB39u-gRWewG4L3Fu_abu2kOY_1c',
    keycloak_realm: 'sbx',
    sentry_enabled: true,
  },
  stg: {
    baseurl: 'https://patients.stg.myome.info',
    apiurl: 'https://api.stg.myome.info/0',
    ga_id: 'G-3D9FQ6NM9K',
    stripe_api_key:
      'pk_test_51LuhuOJBauKDT6tuaPTfQBHEJxbI1yvxs6YiqoZvETf42hyFws6wrMajRRHU7B7zTZRcKttFkNqZqNk2Xjq5HvLF00sqQ06Wsp',
    googleMapsApiKey: 'AIzaSyD0B_jmB39u-gRWewG4L3Fu_abu2kOY_1c',
    keycloak_realm: 'stg',
    sentry_enabled: true,
  },
  dev: {
    baseurl: 'https://patients.dev.myome.info',
    apiurl: 'https://api.dev.myome.info/0',
    ga_id: 'G-3D9FQ6NM9K',
    stripe_api_key:
      'pk_test_51LuhuOJBauKDT6tuaPTfQBHEJxbI1yvxs6YiqoZvETf42hyFws6wrMajRRHU7B7zTZRcKttFkNqZqNk2Xjq5HvLF00sqQ06Wsp',
    googleMapsApiKey: 'AIzaSyCDR9y7bIuzn2VXdYy_vaQf1VAZmChEmEs',
    keycloak_realm: 'dev',
    sentry_enabled: true,
  },
  prd: {
    baseurl: 'https://patients.myome.com',
    apiurl: 'https://api.myome.com/0',
    ga_id: 'G-57S8D12X5M',
    stripe_api_key:
      'pk_live_51LuhuOJBauKDT6tuDeQ7i97Vdteof0b5ADFENzjIAxEMQW7feFAYBxNXLb76PuPI3o8BRgIOYLSsHq9oiJbT02Pj00vjwtGFYN',
    googleMapsApiKey: 'AIzaSyD0B_jmB39u-gRWewG4L3Fu_abu2kOY_1c',
    keycloak_realm: 'prd',
    sentry_enabled: true,
  },
  local: {
    baseurl: 'http://localhost',
    apiurl: process.env.REACT_APP_API_URL || 'https://api.dev.myome.info/0',
    ga_id: 'G-3D9FQ6NM9K',
    stripe_api_key:
      'pk_test_51LuhuOJBauKDT6tuaPTfQBHEJxbI1yvxs6YiqoZvETf42hyFws6wrMajRRHU7B7zTZRcKttFkNqZqNk2Xjq5HvLF00sqQ06Wsp',
    googleMapsApiKey: 'AIzaSyCDR9y7bIuzn2VXdYy_vaQf1VAZmChEmEs',
    keycloak_realm: 'dev',
    sentry_enabled: false,
  },
};

// Default to "dev" if no match
export let envInfo = environments.dev;

for (const envKey in environments) {
  const env = environments[envKey];
  if (window.location.href.startsWith(env.baseurl)) {
    envInfo = env;
    break;
  }
}

export const PLACE_ORDER_STEPS = [
  {
    step: 'checkout',
    icon: ['fa-duotone', 'fa-vial-circle-check'],
    label: 'Test Selection',
  },
  {
    step: 'questionnaire',
    icon: ['fa-duotone', 'fa-hospital-user'],
    label: 'Family History',
  },
  {
    step: 'consents',
    icon: ['fa-duotone', 'fa-list-check'],
    label: 'Consents',
  },
  {
    step: 'review',
    icon: ['fa-duotone', 'fa-receipt'],
    label: 'Review & Submit',
  },
];
// toSpliced is available in most browsers but not in the project's version of node presently. So...
export const PLACE_ORDER_STEPS_WITH_CAD = [
  {
    step: 'checkout',
    icon: ['fa-duotone', 'fa-vial-circle-check'],
    label: 'Test Selection',
  },
  {
    step: 'questionnaire',
    icon: ['fa-duotone', 'fa-hospital-user'],
    label: 'Family History',
  },
  {
    step: 'clinical_data',
    icon: ['fa-duotone', 'fa-hospital-user'],
    label: 'Clinical Data',
  },
  {
    step: 'consents',
    icon: ['fa-duotone', 'fa-list-check'],
    label: 'Consents',
  },
  {
    step: 'review',
    icon: ['fa-duotone', 'fa-receipt'],
    label: 'Review & Submit',
  },
];

export const ORDER_STATUS_STEPS = [
  {
    step: 'SUBMITTED',
    icon: ['fa-duotone', 'fa-box-check'],
    label: 'Order Placed',
  },
  {
    step: 'APPROVED',
    icon: ['fa-duotone', 'fa-memo-circle-check'],
    label: 'Order Approved',
  },
  {
    step: 'AWAITING_SAMPLE',
    icon: ['fa-duotone', 'fa-hourglass-clock'],
    label: 'Awaiting Sample',
  },
  {
    step: 'ANALYZING',
    icon: ['fa-duotone', 'fa-magnifying-glass-chart'],
    label: 'Analysis',
  },
  {
    step: 'CLINICIAN_REVIEW',
    icon: ['fa-duotone', 'fa-user-doctor-message'],
    label: 'Clincian Review',
  },
  {
    step: 'COMPLETED',
    icon: ['fa-duotone', 'fa-file-chart-column'],
    label: 'Results Ready',
  },
];

export const GENDER = [
  { value: 'FEMALE', label: 'Female' },
  { value: 'MALE', label: 'Male' },
  { value: 'OTHER', label: 'Other' },
];

export const QUESTIONNAIRE_QUESTIONS = {
  QNR_FHX_1: {
    options: [
      { displayValue: 'Yes', value: 'YES', showRecommended: false },
      { displayValue: 'No', value: 'NO', showRecommended: false },
    ],
  },
  QNR_FHX_2: {
    options: [
      { displayValue: 'Yes', value: 'YES', showRecommended: false },
      { displayValue: 'No', value: 'NO', showRecommended: false },
    ],
  },
  QNR_FHX_3: {
    options: [
      { displayValue: 'Yes', value: 'YES', showRecommended: false },
      { displayValue: 'No', value: 'NO', showRecommended: false },
    ],
  },
  QNR_FHX_4: {
    options: [
      { displayValue: 'Yes', value: 'YES', showRecommended: false },
      { displayValue: 'No', value: 'NO', showRecommended: false },
    ],
  },
  QNR_FHX_5: {
    options: [
      { displayValue: 'Yes', value: 'YES', showRecommended: false },
      { displayValue: 'No', value: 'NO', showRecommended: false },
    ],
  },
  QNR_FHX_8: {
    options: [
      { displayValue: 'Yes', value: 'YES', showRecommended: false },
      { displayValue: 'No', value: 'NO', showRecommended: false },
    ],
  },
  QNR_FHX_9: {
    options: [
      { displayValue: 'Yes', value: 'YES', showRecommended: false },
      { displayValue: 'No', value: 'NO', showRecommended: false },
    ],
  },
};

export const SMOKING_STATUS = [
  { value: 'CURRENT', label: 'Current Smoker' },
  { value: 'FORMER', label: 'Former Smoker' },
  { value: 'NEVER', label: 'Non-Smoker' },
];

export const CONSENT_CHOICES = [
  {
    label: "I accept MyOme's Informed Consent Agreement",
    value: 'myome:consent:GENERAL-0.0.1',
  },
  {
    label: 'I accept the <a>DNAvisit Physician-of-Record Informed Consent</a>',
    value: 'myome:consent:DNAVISIT-0.0.1',
  },
];

export const US_STATES = [
  { value: 'AL', label: 'AL' },
  { value: 'AK', label: 'AK' },
  { value: 'AZ', label: 'AZ' },
  { value: 'AR', label: 'AR' },
  { value: 'CA', label: 'CA' },
  { value: 'CO', label: 'CO' },
  { value: 'CT', label: 'CT' },
  { value: 'DE', label: 'DE' },
  { value: 'DC', label: 'DC' },
  { value: 'FL', label: 'FL' },
  { value: 'GA', label: 'GA' },
  { value: 'HI', label: 'HI' },
  { value: 'ID', label: 'ID' },
  { value: 'IL', label: 'IL' },
  { value: 'IN', label: 'IN' },
  { value: 'IA', label: 'IA' },
  { value: 'KS', label: 'KS' },
  { value: 'KY', label: 'KY' },
  { value: 'LA', label: 'LA' },
  { value: 'ME', label: 'ME' },
  { value: 'MD', label: 'MD' },
  { value: 'MA', label: 'MA' },
  { value: 'MI', label: 'MI' },
  { value: 'MN', label: 'MN' },
  { value: 'MS', label: 'MS' },
  { value: 'MO', label: 'MO' },
  { value: 'MT', label: 'MT' },
  { value: 'NE', label: 'NE' },
  { value: 'NV', label: 'NV' },
  { value: 'NH', label: 'NH' },
  { value: 'NJ', label: 'NJ' },
  { value: 'NM', label: 'NM' },
  { value: 'NY', label: 'NY' },
  { value: 'NC', label: 'NC' },
  { value: 'ND', label: 'ND' },
  { value: 'OH', label: 'OH' },
  { value: 'OK', label: 'OK' },
  { value: 'OR', label: 'OR' },
  { value: 'PA', label: 'PA' },
  { value: 'RI', label: 'RI' },
  { value: 'SC', label: 'SC' },
  { value: 'SD', label: 'SD' },
  { value: 'TN', label: 'TN' },
  { value: 'TX', label: 'TX' },
  { value: 'UT', label: 'UT' },
  { value: 'VT', label: 'VT' },
  { value: 'VA', label: 'VA' },
  { value: 'WA', label: 'WA' },
  { value: 'WV', label: 'WV' },
  { value: 'WI', label: 'WI' },
  { value: 'WY', label: 'WY' },
];

export const GC_LANGUAGE = [
  'You have indicated a family history of cancer and/or cardiac disease,' +
    ' or that you are uncertain of your family history.',
  "MyOme's screening tests are not necessarily appropriate for individuals " +
    'who have a personal or family history of these diseases.',
  'If your reason for testing is to understand your risks for these diseases in the context of your personal' +
    ' or family history,' +
    ' a diagnostic test may provide a more comprehensive assessment of your risk for these diseases.' +
    ' You should speak with your physician.',
  'If you proceed with testing at MyOme, we recommend that you receive genetic counseling.' +
    ' Genetic counseling will be made available to you at no additional cost' +
    ' to help understand your test results in the context of your personal or family history.',
  'If you do not wish to proceed with testing, please stop now without submitting your order.' +
    ' Your credit card will not be charged and no personal information will be retained.',
];

export const PRODUCT_NOTIFICATIONS_CONSENT = 'myome:consent:PRODUCT_NOTIFICATIONS-0.0.1';

export const PRODUCT_NAMES = {
  'MyOme Personal Genome, Pharmacogenomics': 'Pharmacogenomics Report',
  'MyOme Personal Genome, Proactive Health, 81 Genes': 'Proactive Health Report, 81 Genes',
};

// for the reports page:

export const CURES_ACT_MODAL_BUTTONS_INFO = [
  {
    buttonLabel: 'I’ll wait for my clinician to review my results',
    styleClass: 'primaryButtonStyling',
    btnType: 'WAIT_FOR_RESULTS',
    buttonStyling: {
      marginRight: '12px',
      width: window.innerWidth < 560 ? '100%' : '210px',
      backgroundColor: '#177B21',
      border: '1px solid #177B21',
      padding: '8px',
      fontSize: '12px',
      fontWeight: '700',
    },
  },
  {
    buttonLabel: 'Request report without counseling',
    styleClass: 'dangerButtonStyling',
    btnType: 'REQUEST_WITHOUT_COUNSELING',
    buttonStyling: {
      width: window.innerWidth < 560 ? '100%' : '210px',
      padding: '8px',
      backgroundColor: '#BB0909',
      border: '1px solid #BB0909',
      fontSize: '12px',
      fontWeight: '700',
      color: '#FFFFFF',
    },
  },
];

export const RECEIPT_MODAL_BUTTON_INFO = [
  {
    buttonLabel: 'Print Receipt',
    styleClass: 'secondaryButtonStyling',
    btnType: 'PRINT_RECEIPT',
    buttonStyling: {
      padding: window.innerWidth < 560 ? '5px' : '',
    },
    dataTestId: 'printReceiptBtn',
  },
];

export const CURES_ACT_LANGUAGE = {
  PART_ONE:
    'Your report was recently sent to an independent healthcare provider and has not yet been reviewed.' +
    'You are strongly encouraged to wait for your clinician to review your genetic results and to discuss with them.' +
    'The 21st Century Cures Act requires that reports be made available to patients without delay.' +
    'Pursuant to that law, you may request that we immediately release results to you.' +
    'If you choose this option, you will be able to request genetic counseling after reading your report.',
  PART_TWO:
    'If you choose to move forward with accessing your current reports——ahead of an independent' +
    ' healthcare provider’s review——you will not receive the provider’s clinical summary.',
};

// for the checkout page

export const TEST_SELECTION_LIST = [
  'Clinical grade whole-genome DNA sequencing that can be reanalyzed for future tests with MyOme',
  'Proactive Health Report, Single Gene Risk 84 Genes',
  'Medication Response Report',
  'CAD PRS',
  'Clinician order review with DNAvisit',
  'Post-test genetic counseling to help you understand your results',
  'Access to your results through MyOme’s secure patient portal',
];

export const MIN_AGE = 17;
export const MIN_AGE_CAD = 40;
