/* eslint-disable camelcase */

import ReactGA from 'react-ga4';

export const isLoggedIn = () => {
  return !!localStorage.getItem('access_token');
};

export const firstLetterCapsOnly = str => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

export const formatDate = value => {
  const date = new Date(value);
  const formatted = date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();
  return formatted;
};

export const isYoungerThan = (dateOfBirth, minimumAge) => {
  const [birthYear, birthMonth, birthDay] = dateOfBirth.split('-');
  const currentDate = new Date();
  const [currentYear, currentMonth, currentDay] = [
    currentDate.getFullYear(),
    currentDate.getMonth() + 1,
    currentDate.getDate(),
  ];
  let age = currentYear - birthYear;
  if (age === minimumAge) {
    const intBirthMonth = parseInt(birthMonth);
    const intBirthDay = parseInt(birthDay);
    if (currentMonth < intBirthMonth || (currentMonth === intBirthMonth && currentDay < intBirthDay)) {
      age--;
    }
  }
  return age < minimumAge;
};

export const recordGoogleAnalyticsEvent = (category, action, label) => {
  return ReactGA.event({
    category,
    action,
    label,
  });
};

export const recordGoogleAnalyticsSend = (hitType, page, title) => {
  return ReactGA.send({
    hitType,
    page,
    title,
  });
};
